import { React, useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";
import Select from "react-select";

// Component for Global Filter
export function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="searchbar">
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e?.target?.value);
          onChange(e?.target?.value);
        }}
        placeholder="Search"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10.458"
        height="10.958"
        viewBox="0 0 10.458 10.958"
      >
        <g transform="translate(5.3 5.05)">
          <line
            x2="3.75"
            y2="4.5"
            fill="none"
            stroke="#303030"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <g
            transform="translate(-5.3 -5.05)"
            fill="#fff"
            stroke="#303030"
            strokeWidth="1"
          >
            <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
            <circle cx="4.5" cy="4.5" r="4" fill="none" />
          </g>
        </g>
      </svg>
    </div>
  );
}

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "0",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
};

const dateOptions = [
  { label: "All", value: "" },
  { label: "This Week", value: "1" },
  { label: "This Month", value: "2" },
  { label: "Last 30 Days", value: "3" },
  { label: "Last 3 Months", value: "4" },
  { label: "Year to Date", value: "5" },
];
export function StatusFilter({ globalFilter, setGlobalFilter, changeFilter }) {
  const [value, setValue] = useState(globalFilter);

  const shipmentStatusOptions = [
    { value: "", label: "All Shipments" },
    { value: "released", label: "Released" },
    { value: "submitted", label: "Awaiting Arrival" },
    {
      value: "pending documents",
      label: "Documents Required",
    },
    { value: "PENDING RELEASE", label: "Pending Release" },
    { value: "CBP INTENSIVE EXAM", label: "CBP Intensive Exam" },
    { value: "CBP VACIS EXAM", label: "CBP Vacis Exam" },
    { value: "CBP DOCS REVIEW HOLD", label: "CBP Docs Review Hold" },
    { value: "ISF HOLD", label: "ISF Hold" },
    { value: "IT SHIPMENT", label: "IT Shipment" },
    { value: "NOT CLEARED BY AFB", label: "Not Cleared By AFB" },
  ];

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="status-filter">
      <Select
        styles={customSelectStyles}
        defaultValue={value}
        onChange={(e) => onChange(e.value)}
        options={shipmentStatusOptions}
        className="custom-select"
        placeholder={"All Shipments"}
      />
      <p></p>
      <p>{value}</p>
    </div>
  );
}

export function DateRangeFilter({ setFilter }) {
  const [value, setValue] = useState(null);

  const onChange = useAsyncDebounce((selectedOption) => {
    setValue(selectedOption);
    let today = new Date();
    switch (selectedOption.value) {
      case "1":
        let nextWeek = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 7
        );
        setFilter("date", [formatDate(today), formatDate(nextWeek)]);
        break;
      case "2":
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setFilter("date", [formatDate(firstDayOfMonth), formatDate(lastDayOfMonth)]);
        break;
      case "3":
        let last30Days = new Date(new Date().setDate(today.getDate() - 30));
        setFilter("date", [formatDate(last30Days), formatDate(today)]);
        break;
      case "4":
        let last3Months = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          1
        );
        setFilter("date", [formatDate(last3Months), formatDate(today)]);
        break;
      case "5":
        let ytd = new Date(today.getFullYear(), 0, 1);
        setFilter("date", [formatDate(ytd), formatDate(today)]);
        break;
      default:
        setFilter("date", []);
        break;
    }
  }, 200);

  return (
    <div className="date-filter">
      <Select
        styles={customSelectStyles}
        value={value}
        onChange={onChange}
        options={dateOptions}
        className="custom-select"
        placeholder={"All"}
      />
    </div>
  );
}



export const formatDate = (d) => {
  return (
    [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
    " " +
    [d.getHours(), d.getMinutes(), d.getSeconds()].join(":")
  );
};

export function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (ed || sd) {
    return rows.filter((r) => {
      const cellDate = new Date(r?.values[id]);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

// Component for Default Column Filter
export function DefaultFilterForColumn({
  column: {
    filterValue,
    preFilteredRows: { length },
    setFilter,
  },
}) {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        // Set undefined to remove the filter entirely
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${length} records..`}
      style={{ marginTop: "10px" }}
    />
  );
}

// Component for Custom Select Filter
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Use preFilteredRows to calculate the options
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row?.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // UI for Multi-Select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e?.target?.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
