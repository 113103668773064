import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import ReactRouterPrompt from "react-router-prompt";

import { FaPencilAlt, FaCheck } from "react-icons/fa";
import {
  FaFileDownload as FileIcon,
  FaAngleDown as ArrowDown,
  FaAngleUp as ArrowUp,
} from "react-icons/fa";

import { Title, LoadingTitle } from "../../components/Title";
import LoadingIcon from "../../components/LoadingIcon";
import { DeliveryOrder } from "./DeliveryOrder";
import "../../stylesheets/ShipmentInfo.scss";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions";
import "../../stylesheets/Truckers.scss";
import "../../stylesheets/Warehouse.scss";
import BackwardArrow from '../../assets/nav-bar-icons/arrow-left.svg';
import ForwardArrow from '../../assets/nav-bar-icons/arrow-right.svg';
export const ShipmentInfo = () => {
  let counter = 0;
  const cookies = new Cookies();
  const token = cookies.get("token_user");
  const URL = process.env.REACT_APP_API_URL + "/user";
  const [shipment, setShipment] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  const [state7501, setState7501] = useState(null);

  //ISF, Upload Documents, Shipment release Menus
  const [contentDisplay1, setContentDisplay1] = useState([]);
  const [opened1, setOpened1] = useState(false); //To check if the menu is opened

  const [contentDisplay2, setContentDisplay2] = useState([]);
  const [opened2, setOpened2] = useState(false);

  const [contentDisplay3, setContentDisplay3] = useState([]);
  const [opened3, setOpened3] = useState(false);

  const [withoutISF, setWithoutISF] = useState(false);
  const [ISFBlockedStep, setISFBlockedStep] = useState({
    opacity: "0.5",
    pointerEvents: "none",
  });

  const [userNote, setUserNote] = useState("");
  const [noteLoading, setNoteLoading] = useState(false);
  const [noteSuccess, setNoteSuccess] = useState(false);
  const [newRefNumber, setNewRefNumber] = useState();
  const [uploadError, setUploadError] = useState([]);

  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [aditionalDelivery, setAditionalDelivery] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { filteredShipmentIds, filter } = location.state || {};

  const initialIndex = filteredShipmentIds
    ? filteredShipmentIds.indexOf(parseInt(id, 10))
    : 0;

  const [currentShipmentIndex, setCurrentShipmentIndex] = useState(initialIndex);
  const checkDateValidity = (date) => {
    const date_parsed = new Date(date);
    return !isNaN(date_parsed.getTime()) && date !== "0000-00-00";
  };

  const fetchShipmentData = async (shipmentId) => {
    setLoading(true);
    try {
      const response = await fetch(`${URL}/shipments/details/${shipmentId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setShipment(data.shipment);
    } catch (e) {
      console.error("Error fetching shipment data", e);
      toast.error("Unable to fetch shipment data, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleNavigation = (direction) => {
    if (!filteredShipmentIds || filteredShipmentIds.length <= 1) return;

    if (direction === 'prev') {
      const prevIndex = currentShipmentIndex === 0
        ? filteredShipmentIds.length - 1
        : currentShipmentIndex - 1;
      setCurrentShipmentIndex(prevIndex);
    } else if (direction === 'next') {
      const nextIndex = currentShipmentIndex === filteredShipmentIds.length - 1
        ? 0
        : currentShipmentIndex + 1;
      setCurrentShipmentIndex(nextIndex);
    }
  };

  useEffect(() => {
    if (filteredShipmentIds && filteredShipmentIds.length > 0) {
      fetchShipmentData(filteredShipmentIds[currentShipmentIndex]);

      // Update URL to reflect the current shipment
      navigate(`/shipments/${filteredShipmentIds[currentShipmentIndex]}`, {
        state: { filteredShipmentIds, filter },
      });
    }
  }, [filteredShipmentIds, currentShipmentIndex, filter]);

  const shouldShowButtons = filter && filteredShipmentIds?.length > 1;

  /* Styles */
  const buttonStyle = {
    backgroundColor: "var(--green)",
    marginTop: "1.3rem",
    marginLeft: "auto",
    width: "fit-content",
    display: "block",
    minWidth: "9.2rem",
    height: "3rem",
    border: "1px solid white",
    borderRadius: "5px",
    cursor: "pointer",
    color: "white",
  };

  const greenAndBoldText = {
    color: "var(--green)",
    fontWeight: "bolder",
  };

  const greenAndBoldCircle = {
    border: "2px solid var(--green)",
    backgroundColor: "var(--global-green)",
  };

  /* Handle ISF */
  const handleWithoutISF = async (e) => {
    setWithoutISF(true);
    e.preventDefault();

    const data = {
      isf_num: "N/A",
      date: "N/A",
    };

    try {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/shipments/${id}/isf_no`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (resp.status == 200) {
        var updateShipment = shipment;
        updateShipment.isf_transaction_no = "N\\A";
        setShipment(updateShipment);
        toggleMenu2();
        loadingAnimation(false);
      } else {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
        navigate(0);
      }
    } catch (e) {
      loadingAnimation(false);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  /* Toggle Steps */
  const toggleMenu1 = () => {
    if (opened1) {
      setContentDisplay1({
        display: "none",
      });
      setOpened1(false);
    } else {
      setContentDisplay1({
        display: "block",
        marginBottom: "1rem",
        opacity: 1,
      });
      setOpened1(true);
    }
  };

  const toggleMenu2 = () => {
    if (opened2) {
      setContentDisplay2({
        display: "none",
      });
      setOpened2(false);
    } else {
      setContentDisplay2({
        display: "block",
        marginBottom: "1rem",
        opacity: 1,
      });
      setOpened2(true);
    }
  };

  const toggleMenu3 = () => {
    if (opened3) {
      setContentDisplay3({
        display: "none",
      });
      setOpened3(false);
    } else {
      setContentDisplay3({
        display: "block",
        marginBottom: "1rem",
        opacity: "1",
      });
      setOpened3(true);
    }
  };

  const checkStep = (shipment) => {
    if (shipment) {
      if (
        !shipment.isf_transaction_no ||
        shipment.status === "AWAITING ARRIVAL" ||
        shipment.status === "SUBMITTED"
      ) {
        toggleMenu1();
      } else if (shipment.status === "PENDING DOCUMENTS") {
        toggleMenu2();
      } else {
        toggleMenu3();
      }
    }
  };

  const isStep2Done = () => {
    if (
      shipment.status != "PENDING DOCUMENTS" &&
      shipment.status != "AWAITING ARRIVAL" &&
      shipment.status != "SUBMITTED" &&
      shipment.isf_transaction_no
    ) {
      return true;
    }
    return false;
  };

  const isStep3Active = () => {
    if (
      shipment.status === "PENDING RELEASE" ||
      shipment.status === "UNDER CBP HOLD"
    ) {
      return true;
    }
    return false;
  };

  const toggleIgnoreShipment = async (shipmentId, ignore, category) => {
    try {
      const response = await fetch(`${URL}/shipments/toggle-ignore`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ shipment_id: shipmentId, ignore, category }), // Include category dynamically
      });

      if (response.status === 200) {
        toast.success("Shipment ignore status updated successfully!");
        getShipmentData(); // Refresh the shipment data after toggling ignore
      } else {
        const errorData = await response.json();
        toast.error(
          errorData.error || "Something went wrong, please try again later."
        );
      }
    } catch (error) {
      toast.error("Unable to update shipment ignore status, please try again later.");
      console.error(error);
    }
  };


  /* Upload Files */
  const DropZone = () => {
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      var errors = [];
      if (rejectedFiles.length > 0) {
        errors = rejectedFiles.map(
          (file) =>
            "Couldn't upload " + file.file.path + " - " + file.errors[0].message
        );
      }

      var re = /(?:\.([^.]+))?$/;
      acceptedFiles.forEach(async (file) => {
        const path_name = `changed-${file.path}`;
        Object.defineProperty(file, "name", {
          writable: true,
          value: path_name,
        });
        var ext = re.exec(file.path)[1];
        if (
          ext == "pdf" ||
          ext == "PDF" ||
          ext == "xls" ||
          ext == "xlsx" ||
          ext == "png" ||
          ext == "docx" ||
          ext == "doc"
        ) {
          const formData = new FormData();
          formData.append("file", file);

          try {
            loadingAnimation(true);
            const resp = await fetch(`${URL}/shipments/${id}/upload_doc`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            });
            const data = await resp.json();

            if (resp.status == 200) {
              var updateShipment = shipment;
              updateShipment.documents.push({
                shipment_id: shipment.id,
                document_type: "",
                path: data?.path_to_file,
              });
              setShipment({ ...shipment, documents: updateShipment.documents });
              loadingAnimation(false);
            } else {
              loadingAnimation(false);
              toast.error(
                "Something went wrong. Please, try again in a few minutes!"
              );
            }
          } catch (error) {
            loadingAnimation(false);
            toast.error(
              "Something went wrong. Please, try again in a few minutes!"
            );
          }
        } else {
          errors.push(
            "Couldn't upload " +
            file.path +
            " - " +
            "File type should be pdf, excel or png"
          );
        }
      });

      setUploadError(errors);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: [("image/*": [".jpeg", ".png"])],
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </div>
  );
};

const deleteFile = async (e, doc) => {
  e.preventDefault();

  try {
    loadingAnimation(true);
    const resp = await fetch(`${URL}/shipments/${id}/delete_doc`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ path: doc.path }),
    });

    const res = await resp.json();
    if (res?.success == "Document deleted successfully") {
      var updateShipment = shipment;
      var docs = shipment.documents.filter((file) => file.path != doc.path);
      loadingAnimation(false);
      setShipment({ ...shipment, documents: docs });
    }
  } catch (e) {
    loadingAnimation(false);
    toast.error("Something went wrong. Please, try again in a few minutes!");
  }
};

const handleDocType = async (e, type) => {
  if (type.submitted) {
    const resp = await fetch(`${URL}/shipments/${id}/unset_type`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ types: type.type }),
    });

    const res = await resp.json();

    if (res.type_unset) {
      var updateShipment = shipment;
      var types = updateShipment.types.map((curType) => {
        if (curType.type == type.type) {
          curType["submitted"] = 0;
        }
        return curType;
      });
      setShipment({ ...shipment, types: types });

      if (res.status == "PENDING DOCUMENTS") {
        setShipment({ ...shipment, status: "PENDING DOCUMENTS" });
        setStep2(true);
        setStep3(false);
      }
    }
  } else {
    const resp = await fetch(`${URL}/shipments/${id}/set_type`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ types: type.type }),
    });

    const res = await resp.json();

    if (res.type_set) {
      var updateShipment = shipment;
      var types = updateShipment.types.map((curType) => {
        if (curType.type == type.type) {
          curType["submitted"] = 1;
        }

        return curType;
      });
      setShipment({ ...shipment, types: types });

      if (res.status == "PENDING RELEASE") {
        setShipment({ ...shipment, status: "PENDING RELEASE" });
        toggleMenu2();
        toggleMenu3();
        setStep2(true);
      }
    }
  }
};

const containerNumbers = (containers) => {
  var cont_numbers = "";

  for (let i = 0; i < containers.length; i++) {
    cont_numbers += containers[i].container_no + ", ";
  }

  return <p>{cont_numbers.slice(0, cont_numbers.length - 2)}</p>;
};

/* Notes */
const handleNoteUpload = async (e) => {
  e.preventDefault();
  setNoteLoading(true);

  const data = {
    note: userNote,
  };

  try {
    loadingAnimation(true);
    const resp = await fetch(`${URL}/shipments/${id}/add_comment`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const res = await resp.json();

    if (resp.status == 200) {
      loadingAnimation(false);
      setShipment({ ...shipment, comment: res.message });
      setNoteLoading(false);
      setNoteSuccess(true);
    } else {
      loadingAnimation(false);
      toast.error(
        "Something went wrong. Please, try again in a few minutes!"
      );
      setNoteLoading(false);
    }
  } catch (e) {
    loadingAnimation(false);
    toast.error("Something went wrong. Please, try again in a few minutes!");
  }
};

/* Delivery Orders */
const deliveryOrders = (params) => {
  const { numberOfDeliveries } = params;

  var deliveries = shipment?.delivery_orders
    ? shipment?.delivery_orders?.length
    : 0;
  var num = deliveries + aditionalDelivery;

  const handleNewDelete = (i) => {
    setAditionalDelivery(aditionalDelivery - 1);
  };
  const handleDelete = (i) => {
    delete deliveries[i];
    getShipmentData();
  };

  return (
    <div>
      {deliveries + aditionalDelivery < 1 ? (
        <p className="empty">
          Create your first delivery order by clicking New Delivery Order
          above
        </p>
      ) : (
        <>
          {[...Array(deliveries + aditionalDelivery)].map((_, i) => (
            <div key={i}>
              <DeliveryOrder
                x={i}
                id={id}
                handleDelete={() => {
                  handleDelete(i);
                }}
                handleNewDelete={() => {
                  handleNewDelete(i);
                }}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

/* Reference Number */
const handleRefNumberUpdate = async (e) => {
  e.preventDefault();

  const data = {
    reference_no: newRefNumber,
  };

  try {
    loadingAnimation(true);
    const resp = await fetch(`${URL}/shipments/${id}/reference_no`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.status == 200) {
      loadingAnimation(false);
      setShipment({ ...shipment, customer_reference_no: newRefNumber });
      setNewRefNumber("");
    } else {
      loadingAnimation(false);
      toast.error(
        "Something went wrong. Please, try again in a few minutes!"
      );
    }
  } catch (e) {
    loadingAnimation(false);
    toast.error("Something went wrong. Please, try again in a few minutes!");
  }
};

const getShipmentData = async () => {
  setLoading(true);
  try {
    const url = `${URL}/shipments/details/${id}`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await resp.json();

    const master_bill = [];
    const house_bill = [];

    data?.shipment?.bill_lading?.forEach((bill) => {
      if (bill?.is_master_bill_lading) {
        master_bill.push(bill?.bill_lading);
      } else {
        house_bill.push(bill?.bill_lading);
      }
    });

    data.shipment.master_bill_lading = master_bill.join(", ");
    data.shipment.house_bill_lading = house_bill.join(", ");

    let supplementaryDocumentArr = data?.shipment?.supplementary_documents;

    if (supplementaryDocumentArr?.length > 0 && counter <= 0) {
      counter += 1;
      supplementaryDocumentArr.forEach((item) => {
        if (!item?.deleted_at) {
          setSupplementaryFileArray((oldArray) => [...oldArray, item]);
        }
      });
    }

    setShipment(data.shipment);
    checkStep(data.shipment);
    setLoading(false);

    setState7501(data["7501_reviewed"]);
  } catch (e) {
    console.log(e);
    alert("Server not responding, please contact us.");
  }
};

const getUserData = async () => {
  setLoading(true);
  try {
    const resp = await fetch(`${URL}/profile`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await resp.json();
    setUser(data);
    setLoading(false);
  } catch (e) {
    console.log(e);
    alert("Server not responding, please contact us.");
  }
};

useEffect(() => {
  getShipmentData();
  getUserData();
}, []);

const alertUser = (e) => {
  e.preventDefault();
  e.returnValue = "";
};

const review7501 = (ship_id) => {
  const url = `${URL}/shipments/toggle-reviewed`;
  const body = {
    shipment_ids: [ship_id],
  };
  loadingAnimation(true);
  fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((res) => {
      loadingAnimation(false);
      if (res.reviewed === true) {
        setState7501(1);
      } else if (res.reviewed === false) {
        setState7501(0);
      }
    })
    .catch((e) => {
      loadingAnimation(false);
      console.log(e);
      toast.error(
        "Something went wrong. Please, try again in a few minutes!"
      );
    });
};

// New Supplementary File Upload variables and methods

const [supplementaryFileArray, setSupplementaryFileArray] = useState([]);

return (
  <>
    <ReactRouterPrompt when={newRefNumber}>
      {({ isActive, onConfirm, onCancel }) => {
        if (isActive && newRefNumber) {
          if (
            window.confirm(
              "The reference number wasn't saved. Are you sure you want to leave?"
            )
          ) {
            onConfirm();
          } else {
            onCancel();
          }
        }
      }}
    </ReactRouterPrompt>

    {loading ||
      typeof shipment === "undefined" ||
      typeof user === "undefined" ? (
      <>
        <LoadingTitle />
        <div className="page-content loading"></div>
      </>
    ) : (
      <>
        <Title
          title={`Shipment #${shipment.file_number}`}
          links={[
            {
              link: "/",
              title: "Home",
            },
            {
              link: "/shipments",
              title: "Shipments",
            },
            {
              link: `/shipments/${shipment.id}`,
              title: `#${shipment.file_number}`,
            },
          ]}
        />
        <br />
        {shouldShowButtons && (
          <div className="navigation-arrows">
            <button
              className="arrow-btn"
              onClick={() => handleNavigation('prev')}
              disabled={loading || filteredShipmentIds.length <= 1}
              title="Previous shipment"
            >
              <img src={BackwardArrow} alt="Backward Arrow" className="arrow-icon" />
              <span className="arrow-text">Previous Shipment</span>
            </button>

            <button
              className="arrow-btn"
              onClick={() => handleNavigation('next')}
              disabled={loading || filteredShipmentIds.length <= 1}
              title="Next shipment"
            >
              <span className="arrow-text">Next Shipment</span>
              <img src={ForwardArrow} alt="Forward Arrow" className="arrow-icon" />
            </button>
          </div>
        )}
        <br />
        <div id="grey-line"></div>

        <div className="page-content">
          <div id="shipment-info">
            <div className="left-side">
              <div className="form-process">
                <div className="form-step" id="step-1">
                  <div
                    className={`circle-completed`}
                    style={
                      shipment.isf_transaction_no
                        ? greenAndBoldCircle
                        : opened1
                          ? {
                            backgroundColor: "var(--green)",
                          }
                          : {
                            border: "2px solid var(--border-color)",
                            backgroundColor: "white",
                          }
                    }
                  >
                    {/*Progress circle */}
                    {shipment.isf_transaction_no ? (
                      <span style={greenAndBoldText}>✓</span>
                    ) : (
                      <span
                        style={
                          opened1
                            ? {
                              color: "white",
                            }
                            : {
                              color: "black",
                              fontWeight: "lighter",
                            }
                        }
                      >
                        1
                      </span>
                    )}
                  </div>
                  <div className="form-body">
                    <p className="form-title" onClick={toggleMenu1}>
                      <span
                        style={
                          shipment.isf_transaction_no || opened1
                            ? greenAndBoldText
                            : { color: "black" }
                        }
                      >
                        ISF Submitted
                      </span>
                      <span className="arrow">
                        {opened1 ? <ArrowUp /> : <ArrowDown />}
                      </span>
                    </p>
                    {shipment.isf_transaction_no ? (
                      <div className="form-content" style={contentDisplay1}>
                        <p>
                          {shipment.isf_transaction_no == "N\\A"
                            ? "ISF submitted by client."
                            : `Date filed: ${checkDateValidity(
                              shipment.isf_submit_date
                            ) === true
                              ? format(
                                new Date(shipment.isf_submit_date),
                                "MM/dd/yyyy"
                              )
                              : "NOT AVAILABLE"
                            }`}
                        </p>
                      </div>
                    ) : (
                      <div className="form-content" style={contentDisplay1}>
                        <div className="isf-buttons_container">
                          <div
                            className="checkbox"
                            onClick={handleWithoutISF}
                          ></div>
                          <p>ISF was submitted</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="form-step"
                  id="step-2"
                  style={
                    shipment.isf_transaction_no
                      ? {}
                      : ISFBlockedStep
                  }
                >
                  <div
                    className="circle-completed"
                    style={
                      isStep2Done()
                        ? greenAndBoldCircle
                        : opened2
                          ? {
                            backgroundColor: "var(--green)",
                          }
                          : {
                            border: "2px solid var(--border-color)",
                            backgroundColor: "white",
                          }
                    }
                  >
                    {shipment.status == "RELEASED" ||
                      shipment.status == "PENDING RELEASE" ? (
                      <span style={greenAndBoldText}>✓</span>
                    ) : (
                      <span
                        style={
                          opened2 && !step2
                            ? { color: "black" }
                            : opened2 && step2
                              ? { color: "white" }
                              : {
                                color: "black",
                                fontWeight: "lighter",
                              }
                        }
                      >
                        2
                      </span>
                    )}
                  </div>
                  <div className="form-body">
                    <p className="form-title" onClick={toggleMenu2}>
                      <span
                        style={
                          isStep2Done() || opened2
                            ? greenAndBoldText
                            : { color: "black" }
                        }
                      >
                        Upload Documents
                      </span>
                      <span className="arrow">
                        {opened2 ? <ArrowUp /> : <ArrowDown />}
                      </span>
                    </p>
                    <div className="form-content" style={contentDisplay2}>
                      {shipment.documents.length > 0 && (
                        <div className="docs-wrapper">
                          {shipment.documents.map((doc, index) => {
                            if (doc.document_type === "7501") {
                              return null; // skip this document
                            } else {
                              return (
                                <div className="doc" key={index}>
                                  <a
                                    href="#"
                                    onClick={(e) => deleteFile(e, doc)}
                                    className="delete"
                                  >
                                    <svg
                                      width="11.72"
                                      height="11.719"
                                      viewBox="0 0 11.72 11.719"
                                    >
                                      <path
                                        d="M10.3,9.355l4.218-4.211a.672.672,0,1,0-.951-.951L9.354,8.411,5.143,4.193a.672.672,0,0,0-.951.951L8.41,9.355,4.192,13.566a.672.672,0,1,0,.951.951L9.354,10.3l4.211,4.218a.672.672,0,1,0,.951-.951Z"
                                        transform="translate(-3.494 -3.496)"
                                        fill="#fff"
                                        stroke="#fff"
                                        strokeWidth="1"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href={
                                      process.env.REACT_APP_BASE_URL + "/" + doc.path
                                    }
                                    target="_blank"
                                  >
                                    <svg
                                      width="30"
                                      height="40"
                                      viewBox="0 0 30 40"
                                    >
                                      <path
                                        d="M30,10H20V0ZM20,12.5H30V36.25A3.75,3.75,0,0,1,26.25,40H3.75A3.75,3.75,0,0,1,0,36.25V3.75A3.75,3.75,0,0,1,3.75,0H17.5V10A2.5,2.5,0,0,0,20,12.5Z"
                                        fill="#707070"
                                      />
                                    </svg>
                                  </a>
                                  <p>
                                    <a
                                      href={
                                        process.env.REACT_APP_BASE_URL + "/" + doc.path
                                      }
                                      target="_blank"
                                    >
                                      {doc.path
                                        .substring(
                                          doc.path.lastIndexOf("/") + 1
                                        )
                                        .replace('"', "")}
                                    </a>
                                  </p>
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}

                      {shipment.document_note && (
                        <div className="missing-doc_msg">
                          <div className="warning-circle">
                            <p>!</p>
                          </div>
                          <p className="warning-msg">{shipment.document_note}</p>
                        </div>
                      )}

                      {uploadError && uploadError.length > 0 && (
                        <div className="missing-doc_msg upload-error">
                          <div className="warning-circle">
                            <p>!</p>
                          </div>
                          <p className="warning-msg">
                            {uploadError.map((i, idx) => (
                              <span key={idx}>
                                {i}
                                <br />
                              </span>
                            ))}
                          </p>
                        </div>
                      )}

                      <DropZone />

                      <div className="files_summary">
                        {shipment.types.map((type, i) => (
                          <div className="checkbox-wrapper" key={i}>
                            <label>
                              <input
                                type="checkbox"
                                checked={type.submitted}
                                onChange={(e) => handleDocType(e, type)}
                              />
                              <span className="checkbox">
                                <span>
                                  <svg
                                    width="15.668"
                                    height="15.51"
                                    viewBox="0 0 15.668 15.51"
                                  >
                                    <g transform="matrix(0.995, 0.105, -0.105, 0.995, -120.772, -167.938)">
                                      <line
                                        x2="5"
                                        y2="5"
                                        transform="translate(140.5 161.5)"
                                        fill="none"
                                        stroke="#f6fffb"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                      <line
                                        x1="6"
                                        y2="12"
                                        transform="translate(146 155)"
                                        fill="none"
                                        stroke="#f6fffb"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </span>
                              <span>
                                {type.type}
                                {type.required ? "*" : null}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>

                      <p>Documents Uploaded!</p>
                    </div>

                  </div>
                </div>
                <div
                  className="form-step"
                  id="step-3"
                  style={isStep2Done() ? {} : ISFBlockedStep}
                >
                  <div
                    className="circle-completed"
                    style={
                      shipment.status == "RELEASED"
                        ? greenAndBoldCircle
                        : opened3
                          ? {
                            backgroundColor: "var(--green)",
                          }
                          : {
                            border: "3px solid var(--border-color)",
                            backgroundColor: "white",
                          }
                    }
                  >
                    {/*Progress circle */}
                    {shipment.status == "RELEASED" ? (
                      <span style={greenAndBoldText}>✓</span>
                    ) : (
                      <span
                        style={
                          opened3
                            ? {
                              color: "white",
                            }
                            : {
                              color: "black",
                              fontWeight: "lighter",
                            }
                        }
                      >
                        3
                      </span>
                    )}
                  </div>
                  <div className="form-body">
                    <p className="form-title" onClick={toggleMenu3}>
                      <span
                        style={
                          shipment.status == "RELEASED" ||
                            shipment.status == "PENDING RELEASE" ||
                            opened3
                            ? greenAndBoldText
                            : { color: "black" }
                        }
                      >
                        Shipment Released
                      </span>
                      <span className="arrow">
                        {opened3 ? <ArrowUp /> : <ArrowDown />}
                      </span>
                    </p>
                    <div
                      className="form-content released-content"
                      style={contentDisplay3}
                    >
                      {shipment.status == "RELEASED" && (
                        <p>
                          {checkDateValidity(
                            shipment.shipment_discharge_date
                          ) === true
                            ? format(
                              new Date(shipment.shipment_discharge_date),
                              "MM/dd/yyyy"
                            )
                            : "NOT AVAILABLE"}
                        </p>
                      )}
                      {shipment.status == "RELEASED" &&
                        shipment.fda_review != null ? (
                        <p>FDA Status: {shipment.fda_review}</p>
                      ) : null}
                      {shipment.status == "PENDING RELEASE" && (
                        <p>Shipment is Awaiting Release</p>
                      )}
                      {shipment.status === "CBP INTENSIVE EXAM" ||
                        shipment.status === "CBP VACIS EXAM" ||
                        shipment.status === "CBP DOCS REVIEW HOLD" ||
                        shipment.status === "ISF HOLD" ? (
                        <p>{shipment.status}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="update-order"
                style={{
                  paddingBottom: "16px",
                }}
              >
                <div className="update-header">
                  <div className="ignore-shipment-toggle">
                    <label>
                      <input
                        type="checkbox"
                        checked={shipment?.ignored_shipment?.WithoutDeliveryOrder || false} // Dynamically check based on the "WithoutDeliveryOrder" category
                        onChange={(e) =>
                          toggleIgnoreShipment(
                            shipment.id,
                            e.target.checked,
                            "WithoutDeliveryOrder" // Pass the category dynamically
                          )
                        } // Handle toggle with category
                      />
                      <span className="checkbox">
                        <span>
                          <svg
                            width="15.668"
                            height="15.51"
                            viewBox="0 0 15.668 15.51"
                          >
                            <g transform="matrix(0.995, 0.105, -0.105, 0.995, -120.772, -167.938)">
                              <line
                                x2="5"
                                y2="5"
                                transform="translate(140.5 161.5)"
                                fill="none"
                                stroke="#f6fffb"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="6"
                                y2="12"
                                transform="translate(146 155)"
                                fill="none"
                                stroke="#f6fffb"
                                strokeLinecap="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </span>
                      </span>
                      <span className="checkbox-label">
                        Skip Delivery Order
                      </span>
                    </label>
                  </div>


                  <button
                    href="#"
                    onClick={() => {
                      setAditionalDelivery(aditionalDelivery + 1);
                    }}
                  >
                    New Delivery Order
                  </button>
                </div>
                <div>
                  {deliveryOrders({
                    numberOfDeliveries: shipment.delivery_orders,
                  })}
                </div>
              </div>
              <div className="notes">
                <p className="title">Notes regarding shipment</p>
                {shipment.shipment_notes?.filter((i) => i.role != 3)
                  .length ? (
                  shipment.shipment_notes?.filter((i) => i.role != 3)[
                    shipment.shipment_notes.filter((i) => i.role != 3)
                      .length - 1
                  ].comments.length > 0 ? (
                    <div className="note by-admin">
                      <p style={{ opacity: ".5" }}>
                        {
                          shipment.shipment_notes.filter((i) => i.role != 3)[
                            shipment.shipment_notes.filter((i) => i.role != 3)
                              .length - 1
                          ].comments
                        }
                      </p>
                    </div>
                  ) : null
                ) : null}
                <div className="note-by-user">
                  <form onSubmit={handleNoteUpload} method="post">
                    <textarea
                      value={shipment.comment}
                      className="note-body"
                      onChange={(e) => {
                        setNoteSuccess(false);
                        shipment.comment = e.target.value;
                        setUserNote(e.target.value);
                      }}
                    />

                    {noteSuccess ? (
                      <p className="success-msg">
                        Note successfully submitted
                      </p>
                    ) : null}
                    <button
                      type="submit"
                      style={
                        userNote.length > 0
                          ? {
                            opacity: "1",
                            pointerEvents: "all",
                          }
                          : {
                            opacity: ".5",
                            pointerEvents: "none",
                          }
                      }
                    >
                      {noteLoading ? <LoadingIcon /> : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="shipment-details">
                <p className="title">Shipment Details</p>
                <div className="info-box" id="info-box-1">
                  <div className="info-box-type">
                    <p>Container Number(s)</p>
                  </div>
                  <div className="info-box-value">
                    {containerNumbers(shipment.containers)}
                  </div>
                </div>
                <div className="info-box" id="info-box-2">
                  <div className="info-box-type">Reference Number</div>
                  <div className="info-box-value ref_no">
                    <form method="post" onSubmit={handleRefNumberUpdate}>
                      <input
                        type="text"
                        placeholder={shipment.customer_reference_no}
                        value={newRefNumber}
                        onChange={(e) => {
                          setNewRefNumber(e.target.value);
                        }}
                      />
                      <button
                        type="submit"
                        style={
                          newRefNumber?.length > 0
                            ? {
                              opacity: "1",
                              pointerEvents: "all",
                            }
                            : {
                              opacity: ".5",
                              pointerEvents: "none",
                            }
                        }
                      >
                        {newRefNumber?.length > 0 ? (
                          <FaCheck className="green" />
                        ) : (
                          <FaPencilAlt />
                        )}
                      </button>
                    </form>
                  </div>
                </div>
                <div className="info-box" id="info-box-3">
                  <div className="info-box-type">ISF Number</div>
                  <div className="info-box-value">
                    {shipment.isf_transaction_no}
                  </div>
                </div>
                {shipment.master_bill_lading &&
                  shipment.master_bill_lading.length > 0 && (
                    <div className="info-box" id="info-box-4">
                      <div className="info-box-type">
                        Master Bill of Lading
                      </div>
                      <div className="info-box-value">
                        {shipment.master_bill_lading}
                      </div>
                    </div>
                  )}
                {shipment.house_bill_lading &&
                  shipment.house_bill_lading.length > 0 && (
                    <div className="info-box" id="info-box-5">
                      <div className="info-box-type">
                        House Bill of Landing
                      </div>
                      <div className="info-box-value">
                        {shipment.house_bill_lading}
                      </div>
                    </div>
                  )}
                <div className="info-box" id="info-box-6">
                  <div className="info-box-type">Origin</div>
                  <div className="info-box-value">{shipment.origin}</div>
                </div>
                <div className="info-box" id="info-box-7">
                  <div className="info-box-type">Destination</div>
                  <div className="info-box-value">
                    {shipment.unlading_code.name}
                  </div>
                </div>
                <div className="info-box" id="info-box-8">
                  <div className="info-box-type">Destination Terminal</div>
                  <div className="info-box-value">
                    {shipment.destination_terminal}
                  </div>
                </div>
                <div className="info-box" id="info-box-9">
                  <div className="info-box-type">Steamship Line</div>
                  <div className="info-box-value">
                    {shipment.steamship_line}
                  </div>
                </div>
                <div className="info-box" id="info-box-10">
                  <div className="info-box-type">Vessel</div>
                  <div className="info-box-value">{shipment.vessel_name}</div>
                </div>
                <div className="info-box" id="info-box-11">
                  <div className="info-box-type">Mode of Transport</div>
                  <div className="info-box-value">
                    {shipment.transport_mode.description.replace(
                      "vessele:",
                      ""
                    )}
                  </div>
                </div>
                <div className="info-box" id="info-box-12">
                  <div className="info-box-type">ETA</div>
                  <div className="info-box-value">
                    {checkDateValidity(shipment.est_time_arrive) === true
                      ? format(
                        new Date(shipment.est_time_arrive),
                        "MM/dd/yyyy"
                      )
                      : "NOT AVAILABLE"}
                  </div>
                </div>
              </div>
              {state7501 !== null ? (
                <div
                  className="review-7501-toggle"
                  onClick={() => {
                    review7501(shipment?.id);
                  }}
                >
                  <div className="checkbox-ship">
                    {state7501 == 0 ? "" : <p className="checked-item">✓</p>}
                  </div>
                  <p>7501 has been reviewed</p>
                </div>
              ) : (
                ""
              )}
              <div className="files">
                <a
                  target={"_blank"}
                  className={`file ${shipment?.path_to_7501 ? "active-link" : "no-link"}`}
                  id="file-1"
                  href={
                    shipment?.path_to_7501
                      ? `${process.env.REACT_APP_BASE_URL + "/" + shipment?.path_to_7501}`
                      : `#`
                  }
                >
                  <FileIcon />
                  <p>7501 Form</p>
                </a>

                {shipment?.invoices && shipment.invoices.length > 0 ? (
                  shipment.invoices.map((invoice, index) => (
                    <a
                      key={index}
                      target="_blank"
                      className="file active-link"
                      id={`file-invoice-${index}`}
                      href={`${process.env.REACT_APP_BASE_URL +
                        "/" +
                        invoice.path.replace(
                          "/var/www/html/atlantic-brokers/storage/app/public/",
                          ""
                        )
                        }`}
                      download={`invoice-${index + 1}.pdf`}
                    >
                      <FileIcon />
                      <p>Billing Invoice {index + 1}</p>
                    </a>
                  ))
                ) : (
                  <a className="file no-link" id="file-no-invoice" href="#">
                    <FileIcon />
                    <p>Billing Invoice</p>
                  </a>
                )}

                {shipment.delivery_orders && shipment.delivery_orders.length > 0 ? (
                  shipment.delivery_orders
                    .filter((order) => order?.path?.length > 0)
                    .map((order, orderIndex) => (
                      <a
                        key={orderIndex}
                        href={process.env.REACT_APP_BASE_URL + order.path}
                        target="_blank"
                        className="file active-link"
                        id={`file-order-${orderIndex}`}
                      >
                        <div className="icon green">
                          <FileIcon />
                        </div>
                        <p>Delivery Order {orderIndex + 1}</p>
                      </a>
                    ))
                ) : (
                  <a href="#" className="file no-link" id="file-no-delivery-order">
                    <FileIcon />
                    <p>Delivery Order</p>
                  </a>
                )}
              </div>

              {supplementaryFileArray?.length > 0 ? (
                <section className={"supplementary-file-section"}>
                  <p className={"supplementary-files-title"}>
                    Additional Files
                  </p>
                  <div className="supplementary-files-container files">
                    {supplementaryFileArray.map((item, index) => {
                      const fileName = item?.path.slice(
                        item?.path.lastIndexOf("/") + 1
                      );
                      return (
                        <a
                          target={"_blank"}
                          className={"file active-link file-card"}
                          id={`file-${index + 4}`}
                          href={
                            process.env.REACT_APP_BASE_URL + "/" + item?.path
                          }
                          download={fileName}
                          key={index}
                        >
                          <FileIcon />
                          <p className="file-card-name">{fileName}</p>
                        </a>
                      );
                    })}
                  </div>
                </section>
              ) : (
                <section></section>
              )}
            </div>
          </div>
        </div>
      </>
    )}
  </>
);
};